import React from 'react';
import Header from '../Header/Header';
import fruit_image from "../../assets/fruit_image.png";
import fruit_image_back from "../../assets/fruit_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";
import {motion} from 'framer-motion';
import NumberCounter from "number-counter";

import './Fruit.css';
const Fruit = () => {
  const transition ={type: 'spring' , duration: 3};
  const mobile = window.innerWidth<=768 ? true: false;
  return (
    <div className="fruit" id="home">
      <div className="blur fruit-blur"></div>
         <div className="left-h"><Header/>
         <div className="the-best-ad">
          <motion.div 
          initial={{left: mobile? "165px":'238px'}} 
          whileInView={{left: '8px'}}
          transition={{...transition, type: 'tween'}}></motion.div>
          <span>the best fruit pharmacy</span>
         </div>
         <div className="fruit-text">
          <div><span className="stroke-text">
            Shape </span>
            <span>Your</span></div>
          <div>
          <span>
            Ideal body
          </span>
          </div>
          <div><span>Knowledge is knowing that a tomato is a fruit. Wisdom is knowing not to put it in a fruit salad.</span></div>
         </div>
         <div className="figures">
          <div>
            <span><NumberCounter end={140} start={100} delay='4' prefix="+"/></span>
            <span>Healthy products</span>
              </div>
              <div>
            <span>
              <NumberCounter end={1000} start={500} delay='4' prefix="+"/></span>
            <span>members purchased</span>
              </div>
              <div>
            <span><NumberCounter end={100} start={0} delay='4' prefix="+"/></span>
            <span>reviews</span>
              </div>
         </div>
         <div className="fruit-buttons">
          <buttons className="btn">Get Started</buttons>
          <buttons className="btn">Learn More</buttons>

         </div>
         </div>
         <div className="right-h">
          <button className="btn">Get Now</button>

          <motion.div 
          initial={{right: "-1rem"}}
          whileInView={{right: "4rem"}}
          transition={transition}
          className="heart-rate">
            <img src={Heart} alt=""/>
            <span>Heart Rate</span>
            <span>116 bpm</span>
          </motion.div>

          <img src={fruit_image} alt=""className="fruit-image" />
          <motion.img 
          initial={{right:"11rem"}}
          whileInView={{right:"20rem"}}
          transition={transition}
          src={fruit_image_back} alt=""className="fruit-image-back" />
         <motion.div 
         initial={{right:"37rem"}}
         whileInView={{right:"28rem"}}
         transition={transition}
         className="calories">
          <img src={Calories} alt="" />
          <div>
          <span>Calories burned</span>
          <span>220 Kcal</span>
          </div>
          
         </motion.div>
         </div>
    </div>
  )
}

export default Fruit