import React ,{useRef} from 'react'
import "./Join.css"
import emailjs from '@emailjs/browser';
const Join = () => {

    const form=useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_73pm0g8', 'template_nydun66', form.current, 'VM76ElLPOZSSTEXAU')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
    <div className="Join" id="join-us">
        <div className="left-j">
            <hr/>
        <div>
            <span className="stroke-text">READY TO</span>
            <span>JOIN US</span>
        </div>
        <div>
            <span>FOR A BEST</span>
            <span className="stroke-text">MEMBERSHIP?</span>
        </div>
        </div>
        
        <div className="right-j">
            <form ref={form} className="email-container" onSubmit={sendEmail}>
                <input type="email" name="user_email" placeholder="Enter your email address"/>
                <button className="btn btn-j">Join Now</button>
            </form>
        </div>
    </div>
  )
}

export default Join