import image1 from "../assets/t-image.jpg";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "It's a concentrate of aloevera pulp. I could not identify the smell of any preservative. ",
    name: 'PARTHASARATHI',
    status : 'PROFESSOR'
  },
  {
    image: image2,
    review: 'I was recommended to use this by a friend, has real aelovera chunks. Decent quality.',
    name: 'RUBEN GERAD',
    status: 'STUDENT'
  },
  {
    image : image3,
    review:' Real authentic Amla pulp.Worth buying product.',
    name: 'HARDIK PATEL',
    status: "DOCTOR"
  }
];
