import React from 'react';
import './Regions.css';

import image1 from "../../assets/booster.jpg";
import image2 from "../../assets/smoothies.jpg";
import image3 from "../../assets/treat.jpg";
import image4 from "../../assets/cold pressed.jpg";
import nb from "../../assets/nb.png";
import adidas from "../../assets/adidas.png";
import nike from "../../assets/nike.png";
import tick from "../../assets/tick.png";


const Regions =() => {
    return(
        <div className="Regions" id="regions">
            <div className="left-r">
                <img src={image1} alt="" />
                <img src={image2} alt="" />
                <img src={image3} alt="" />
                <img src={image4} alt="" />
            </div>
            <div className="right-r"><span>some reasons</span>
            <div><span className="stroke-text">why</span><span> choose us?</span></div>
            <div className="details-r">
            <div><img src={tick} alt=""></img><span>ENRICHING FOOD PRODUCTS</span></div>
            <div><img src={tick} alt="" /><span>SUGAR FREE PRODUCTS</span></div>
            <div><img src={tick} alt="" /><span>CUSTOMR CARE AVAILABLE</span></div>
            <div><img src={tick} alt="" /><span>SITE WORKING FOR 24*7</span></div>
            </div>
            <span style={{color : "var(--gray)", fontWeight:'normal'}
                
            }>
                OUR PARTNERS
            </span>
            <div className="partners">
                <img src={nb} alt=""/>
                <img src={adidas} alt=""/>
                <img src={nike} alt=""/>
            </div>
        </div>
        </div>
    )
}
    
        
    

export default Regions