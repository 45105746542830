import React from 'react'
import whiteTick from "../../assets/whiteTick.png"
import "./Plans.css"
import {plansData} from "../../data/plansData"
const Plans = () => {
  return (
    <div className="plans-container">
        <div className="blur plans-blur-1"></div>
        <div className="blur plans-blur-2"></div>
        <div className="programs-header" style={{gap:'2rem'}}>
            <span className="stroke-text">ready to</span><span>purchase products</span><span className="stroke-text">with us.</span>
        </div>
        <div className="plans" id="order">
            {plansData.map((plan,i)=>(
                <div className="plan" key={i}>
                    {plan.icon}
                    <span>{plan.name}</span><span>{plan.price}</span>
                    <div className="features">{plan.features.map((feature,i)=>(
                         <div className="feature">
                            <img src={whiteTick} alt=""/>
                            <span key={i}>{feature}</span>
                         </div>
                    ))}
                    </div>
                    <div><span>See more benefits -></span></div>
                    <button className="btn">Purchase now</button>
                </div>
            ))}

        </div>
    </div>
  )
}

export default Plans