import './App.css';
import Fruit from './components/Fruit/Fruit';
import Programs from './components/Programs/Programs';
import Regions from './components/Regions/Regions';
import Plans from './components/Plans/Plans';
import Join from './components/Join/Join';
import Testimonials from './components/Testimonials/Testimonials';
import Footer from './components/Footer/Footer';
function App() {
  return (
    <div className="App">
          <Fruit/>
          <Programs/>
          <Regions/>
          <Plans/>
          <Testimonials/>
          <Join/>
          <Footer/>
    </div>
  );
}

export default App;
